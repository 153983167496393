import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "navbar navbar-expand-md navbar-dark bg-dark" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = {
  class: "collapse navbar-collapse",
  id: "navbarText"
}
const _hoisted_4 = {
  key: 0,
  class: "navbar-nav me-auto"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "ms-auto navbar-nav navbar-end" }
const _hoisted_7 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_8 = { class: "navbar-text me-2" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  class: "pt-3 px-3 flex-fill",
  style: {"overflow-y":"auto"}
}

import { computed, provide } from "vue"
import { useRoute, useRouter } from "vue-router"

import { Role, useAuthStore } from "./stores/auth"
import MessagingPopup from "./components/MessagePopup/MessagingPopup.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute()
const router = useRouter()
const auth = useAuthStore()

const routes = computed(() => {
  const list = []
  if (currentUserRole.value == Role.Admin) {
    list.push({
      to: "/stores",
      title: "Stores"
    })
  }
  if (currentUserRole.value != Role.Driver) {
    list.push({
      to: "/users",
      title: "Users"
    })
    list.push({
      to: "/drivers",
      title: "Drivers"
    })
  }
  list.push({
    to: "/deliveries",
    title: "Deliveries"
  })
  return list
})

function logout() {
  auth.logout()
}

const currentUserRole = computed(() => auth.userInfo?.role ?? Role.None)
const user = computed(() => {
  const auth = useAuthStore()
  if (auth.isAuthenticated()) {
    return auth.userInfo?.name
  }
  return undefined
})

provide("setQuery", setQuery)

async function setQuery(key: string, value?: string) {
  const query = route.query
  return router.replace({ query: { ...query, [key]: value } })
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("a", {
          class: "navbar-brand",
          href: "/"
        }, "Datascan Delivery", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("button", {
          class: "navbar-toggler",
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarText",
          "aria-controls": "navbarText",
          "aria-expanded": "false"
        }, [
          _createElementVNode("span", { class: "navbar-toggler-icon" })
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          (user.value)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(routes.value, (route) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    key: route.to,
                    to: route.to,
                    custom: ""
                  }, {
                    default: _withCtx(({ isActive, navigate }) => [
                      _createElementVNode("li", {
                        class: "nav-item",
                        onClick: navigate
                      }, [
                        _createElementVNode("span", {
                          class: _normalizeClass(["nav-link", { active: isActive }])
                        }, _toDisplayString(route.title), 3)
                      ], 8, _hoisted_5)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("ul", _hoisted_6, [
            (user.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("li", _hoisted_8, "Hi " + _toDisplayString(user.value) + "!", 1),
                  _createElementVNode("li", {
                    class: "nav-link",
                    onClick: logout
                  }, "Log out")
                ]))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: "Login",
                  custom: ""
                }, {
                  default: _withCtx(({ navigate }) => [
                    _createElementVNode("li", {
                      class: "nav-item",
                      onClick: navigate
                    }, _cache[0] || (_cache[0] = [
                      _createElementVNode("span", { class: "nav-link" }, "Log in", -1)
                    ]), 8, _hoisted_9)
                  ]),
                  _: 1
                }))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(MessagingPopup),
      _createVNode(_component_router_view, { class: "h-100 d-flex flex-column" })
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("hr", { class: "m-0" }, null, -1)),
    _cache[4] || (_cache[4] = _createElementVNode("footer", { class: "text-center pb-3" }, [
      _createElementVNode("small", null, "© 2023 - Datascan Delivery Portal")
    ], -1))
  ], 64))
}
}

})