import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-secondary me-3 text-end",
  style: {"font-size":"12px"}
}
const _hoisted_2 = { class: "message-bubble" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "message-status" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 2,
  class: "spinner-border spinner-border-sm mt-1"
}
const _hoisted_8 = { key: 3 }

import { Message } from "@/api/messages"


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageRow',
  props: {
    message: {}
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message-row", { incoming: props.message.fromDriver }])
  }, [
    (!props.message.fromDriver)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(props.message.userName), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        title: props.message.sentTime.toString()
      }, _toDisplayString(props.message.messageText), 9, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.message.isRead)
        ? (_openBlock(), _createElementBlock("small", _hoisted_5, "Read"))
        : (_ctx.message.isDelivered)
          ? (_openBlock(), _createElementBlock("small", _hoisted_6, "Delivered"))
          : (_ctx.message.messageId?.startsWith('tmp'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7))
            : (_openBlock(), _createElementBlock("small", _hoisted_8, "Sent"))
    ])
  ], 2))
}
}

})