import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "avatar" }
const _hoisted_2 = { class: "info" }
const _hoisted_3 = { class: "d-flex justify-content-between" }
const _hoisted_4 = { class: "driver-name" }
const _hoisted_5 = { class: "last-message" }

import { MessageCount } from "@/api/messages"
import { computed } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'DriverMessageRow',
  props: {
    count: {}
  },
  emits: ["selected"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const timeString = computed(() => {
  const time = props.count.lastMessageTime
  if (!time) return ""
  const now = new Date()
  const time1 = time.getTime()
  const time2 = now.getTime()
  const msPerDay = 86400000
  const dateOnly1 = time1 - (time1 % msPerDay)
  const dateOnly2 = time2 - (time2 % msPerDay)
  if (dateOnly1 == dateOnly2) return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  else if (dateOnly2 - dateOnly1 == msPerDay) return "Yesterday"
  else return time.toLocaleDateString([], { month: "short", day: "numeric" })
})

const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["driver-row", { unread: _ctx.count.unreadMessages > 0 }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('selected')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Icon, {
        icon: 'ic:person',
        class: "w-100 h-100"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.count.driverName), 1),
        _createElementVNode("small", null, _toDisplayString(timeString.value), 1)
      ]),
      _createElementVNode("small", _hoisted_5, _toDisplayString(_ctx.count.lastMessageText), 1)
    ])
  ], 2))
}
}

})